<template>
  <div class="information-wrapper">
    <el-dialog
      :visible.sync="show"
      :before-close="closeDialog"
      center
      :show-close="false"
      class="image-prediv-applet-content"
      :close-on-click-modal="false"
      width="100%"
    >
      <div class="out-wrapper">
        <div class="img-wrapper">
          <div class="applet-wrapper">
            <div class="applet-head">
              <img src="@/assets/images/pre-start1.png" alt="" srcset="" />
              <!-- <div class="name">资讯详情</div> -->
            </div>
            <div class="preview-content">
              <div class="collection-content" v-html="form.detail"></div>
            </div>
          </div>
          <div class="prediv-over" @click="closeDialog">结束预览</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import apis from '@/apis'
import mixin from "@/mixins/dialogMixin";
import IndexClass from "./indexClass.js";

export default {
  mixins: [mixin],
  //部件
  components: {},
  //静态
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    src: {
      type: String,
      default: "",
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new IndexClass("form"),
    };
  },
  //对象内部的属性监听，也叫深度监听
  watch: {},
  //属性的结果会被缓存，除非依赖的响应式属性变化才会重新计算。主要当作属性来使用；
  computed: {},
  //方法表示一个具体的操作，主要书写业务逻辑；
  methods: {
    closeDialog() {
      this.$emit("closeDialog", false);
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getPreviewData(preData) {
      this.form = {
        ...this.form,
        ...preData,
      };
    },
  },
  //请求数据
  created() {},
  mounted() {},
};
</script>
<style>
.collection-content img {
  width: 100%;
}
</style>
<style lang="less" scoped>
.image-prediv-applet-content {
  padding: 17px 15px;
  .out-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .applet-content {
    margin: 18px 15px;
    width: 375px;
    .infortion-item {
      background: rgba(255, 255, 255, 1);
      border-radius: 12rpx;
      padding: 12px;
      .title {
        font-weight: 500;
        color: rgba(23, 32, 66, 1);
        line-height: 2px;
        font-size: 15px;
        margin-bottom: 3px;
        font-weight: bold;
      }
      .tip-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: rgba(153, 153, 153, 1);
        font-size: 10px;
        margin-bottom: 8px;
        .right {
          .images {
            width: 12px;
            height: 9px;
            margin-right: 8px;
          }
        }
      }
      .center {
        font-weight: 400;
        color: rgba(82, 82, 82, 1);
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 8px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .bottom-images {
      width: 100%;
      height: 348rpx;
      border-radius: 8rpx;
    }
  }
  .img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .prediv-over {
    cursor: pointer;
    height: 49px;
    width: 375px;
    line-height: 49px;
    background: rgba(61, 100, 236, 1);
    border-radius: 25px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
  }
  .applet-wrapper {
    width: 375px;
    min-height: 667px;
    background: #f7f8fa;
  }
  .applet-head {
    background: #3d64ec;
    height: 64px !important;
    height: 100%;
    position: relative;
    .name {
      font-size: 17px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 24px;
      position: absolute;
      left: 33px;
      bottom: 11px;
    }
  }
  .applet-content {
    margin: 18px 15px;
    .infortion-item {
      background: rgba(255, 255, 255, 1);
      border-radius: 6px;
      padding: 12px;
      .title {
        font-weight: 500;
        color: rgba(23, 32, 66, 1);
        line-height: 22px;
        font-size: 15px;
        margin-bottom: 3px;
      }
      .tip-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: rgba(153, 153, 153, 1);
        font-size: 10px;
        margin-bottom: 10px;
        .right {
          .images {
            width: 12px;
            height: 9px;
          }
        }
      }
      .center {
        font-weight: 400;
        color: rgba(82, 82, 82, 1);
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 8px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .bottom-images {
      width: 100%;
      height: 174px;
    }
  }
}
.preview-content {
  padding: 1px 15px;
}
.collection-title {
  letter-spacing: 2px;
  font-weight: 500;
  color: rgba(23, 32, 66, 1);
  font-size: 17px;
  margin-top: 16px;
}
.collection-content {
  margin-top: 16px;
  color: rgba(82, 82, 82, 1);
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
}
</style>
<style lang="less">
.image-prediv-applet-content {
  .el-dialog {
    background: transparent !important;
  }
  .el-dialog__header {
    border-bottom: 0 !important;
  }
}
</style>
