<template>
  <div class="wrap-box convenience">
    <div class="wrap-info">基本信息</div>
    <div class="form-content">
      <el-form ref="form" label-width="80px" :model="form" :rules="rules">
        <el-row>
          <el-col :span="14">
            <el-form-item label="视频名称" prop="videoName" class="video-input">
              <span class="video-text">{{ form.videoName.length }}/45</span>
              <el-input
                v-model="form.videoName"
                maxlength="45"
                placeholder="请输入视频名称（字数在14字以内，不能超过45个字）"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="视频上传" prop="videoAddress">
              <div class="suggested-text">
                格式支持MP4，大小不超过1G，建议视频分辨率1280*720
              </div>
              <div>
                <el-upload
                  class="upload"
                  :http-request="uploadFile"
                  :with-credentials="true"
                  :action="apis.ImageUpload"
                  :httpRequest="uploadHttpDefault"
                  :headers="{
                    token: TOKEN,
                  }"
                  name="file"
                  accept=".mp4"
                  :limit="1"
                  :file-list="fileList"
                  :on-change="handleChange"
                  :before-upload="beforeUploadVideo"
                  :on-success="handleVideoSuccess"
                  :on-error="uploadVideoError"
                  :on-remove="handleRemove"
                >
                  <div class="upload-btn" slot="trigger">选择文件</div>
                </el-upload>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="视频封面" prop="videoImgAddress">
              <div class="suggested-text">
                建议尺寸700*450px或14：9，JPG、PNG格式，图片小于5M
              </div>
              <div>
                <el-upload
                  v-if="showPic"
                  class="upload"
                  :http-request="uploadFile"
                  :show-file-list="false"
                  :with-credentials="true"
                  :action="apis.ImageUpload"
                  :httpRequest="uploadHttpDefault"
                  :headers="{
                    token: TOKEN,
                  }"
                  name="file"
                  accept=".png,.jpg"
                  :limit="1"
                  :before-upload="beforeUpload"
                  :on-success="uploadSuccess"
                  :on-error="uploadError"
                >
                  <div>
                    <img
                      v-if="form.videoImgAddress"
                      :src="form.videoImgAddress"
                      alt
                      srcset
                      width="130"
                      height="84"
                    />
                    <div class="upload-btn" slot="trigger">选择文件</div>
                  </div>
                </el-upload>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="视频详情" prop="detail">
              <div class="edit_container">
                <quill-edit
                  :detail="form.detail"
                  @change="onEditChange"
                  :qnLocation="apis.ImageUpload"
                >
                </quill-edit>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-button
              class="footer-confirm"
              @click="toPreview"
              style="margin-top: 30px"
              >预览</el-button
            >
          </el-col>
          <el-col :span="24">
            <el-form-item label="定价" prop="positioning">
              <div>
                <el-radio v-model="form.positioning" :label="2">付费</el-radio>
                <el-radio v-model="form.positioning" :label="1">免费</el-radio>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div v-if="form.positioning === 2">
              <el-form-item label="售价" prop="price">
                <div style="display: flex">
                  <el-input
                    v-model="form.price"
                    style="width: 217px"
                  ></el-input>
                  <div class="mg">元</div>
                </div>
              </el-form-item>
              <el-form-item label="划线价" prop="linePrice">
                <div style="display: flex">
                  <el-input
                    v-model="form.linePrice"
                    style="width: 217px"
                  ></el-input>
                  <div class="mg">元</div>
                </div>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <el-form-item label="视频分类" prop="typeIds">
              <el-select v-model="form.typeIds" multiple placeholder="请选择">
                <el-option
                  v-for="item in selectTypeList"
                  :key="item.id"
                  :label="item.typeName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="btn-box">
          <el-button class="footer-confirm" @click="toSave(1)">上架</el-button>
          <el-button class="footer-cancel" @click="toSave(2)">保存</el-button>
          <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        </div>
      </el-form>
    </div>
    <!-- 图片预览 -->
    <preview-image
      ref="previewImage"
      :show="previewImage.visible"
      @closeDialog="
        () => {
          this.previewImage.visible = false;
        }
      "
    ></preview-image>
  </div>
</template>
<script>
import quillEdit from "@/components/quillEdit.vue";
import previewImage from "./preview";
import apis from "@/apis";
import mixin from "@/mixins/index";
import { mapState } from "vuex";
import IndexClass from "./indexClass";
import { Loading } from 'element-ui';

export default {
  mixins: [mixin],
  components: {
    quillEdit,
    previewImage,
  },
  computed: {
    ...mapState(["Language", "TOKEN"]),
  },
  data() {
    return {
      form: new IndexClass("form"), // 实例化一个表单的变量
      rules: new IndexClass("rule", this.Language), // 实例化一个表单的规则
      fileList: [],
      selectTypeList: [],
      value: [],
      // showImg: false,
      videoList: [],
      previewImage: {
        visible: false,
      },
      isEdit: false,
      id: "",
      testForm: {},
      showPic: true,
    };
  },
  watch: {
    $route(to) {
      this.fileList = [];
      if (to.query.Id) {
        this.id = to.query.Id;
        this.isEdit = true;
        this.getDetail(to.query.Id);
      } else {
        this.isEdit = false;
        this.$nextTick(() => {
          this.$refs.form.resetFields();
        });
      }
    },
  },
  methods: {
    /**
     * @function 文本编辑器
     */
    onEditChange(value) {
      this.form.detail = value;
      this.$refs.form.validateField("detail");
    },
    /**
     * @function 上架或保存
     */
    toSave(type) {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isEdit) {
            const editForm = {
              typeId: this.form.typeIds,
              videoAddress: this.form.videoAddress,
              venueVideoCourse: {
                videoName: this.form.videoName,
                details: this.form.detail,
                positioning: this.form.positioning,
                price: this.form.price,
                linePrice: this.form.linePrice,
                videoImgAddress: this.form.videoImgAddress,
                publishStatus: type,
                id: this.id,
              },
            };
            this.$http
              .put(apis.updateVideoCourse, {
                ...editForm,
              })
              .then((res) => {
                if (res.data.code === 0) {
                  this.$message.success(`编辑成功`);
                  this.$router.push({ name: "onlineVideo" });
                }
              });
          } else {
            const addForm = {
              typeId: this.form.typeIds,
              videoAddress: this.form.videoAddress,
              venueVideoCourse: {
                videoName: this.form.videoName,
                details: this.form.detail,
                positioning: this.form.positioning,
                price: this.form.price,
                linePrice: this.form.linePrice,
                videoImgAddress: this.form.videoImgAddress,
                publishStatus: type,
              },
            };
            this.$http
              .post(apis.addVideoCoures, {
                ...addForm,
              })
              .then((res) => {
                if (res.data.code === 0) {
                  const typeTitle = type === 1 ? "上架成功" : "发布成功";
                  this.$message.success(`${typeTitle}`);
                  this.$router.push({ name: "onlineVideo" });
                }
              });
          }
        }
      });
    },
    uploadSuccess(res) {
      this.form.videoImgAddress = res.data.url;
      this.showPic = false;
      this.$nextTick(() => {
        this.showPic = true;
      });
      this.$refs.form.validateField("videoImgAddress");
    },
    handleVideoSuccess(res) {
      this.loadingInstance.close()
      this.$message({
        message: '视频上传成功',
        type: 'success',
      });
      this.form.videoAddress.push(res.data.url);
      this.$refs.form.validateField("videoAddress");
    },
    // 图片格式校验
    beforeUpload(file) {
      // 格式校验
      const isFormat = file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg";
      // 校验图片大小
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.warning("上传图片大小不能超过5MB");
      }
      if (!isFormat) {
        this.$message.warning("上传的图片类型错误");
      }
    },
    // 视频格式校验
    beforeUploadVideo(file) {
      console.log(file);
      // 校验图片大小
      const isLt1M = file.size / 1024 / 1024 < 1024;
      if (!isLt1M) {
        this.$message.warning("上传视频大小不能超过1G");
      } else {
        this.loadingInstance = Loading.service({
          fullscreen: true,
          text: '视频上传中，请等待',
          background: 'rgba(255,255,255,.5)',
        });
      }
      return !!isLt1M;
    },
    uploadError(res) {
      console.log(res);
    },
    uploadVideoError(err) {
      this.loadingInstance.close()
      this.$message.error('视频上传失败');
      console.log(err);
    },
    // beforeRemove(file, fileList) {
    //   console.log(fileList);
    //   return this.$confirm(`确定移除 ${file.name}？`);
    // },
    handleRemove() {
      this.form.videoAddress = [];
    },
    uploadFile(file) {
      console.log(file);
    },
    toPreview() {
      this.previewImage.visible = true;
      this.$nextTick(() => {
        this.$refs["previewImage"].getPreviewData(this.form);
      });
    },
    handleChange(file, fileList) {
      console.log('onChange', file, fileList);
    },
    getSelectTypeList() {
      this.$http.post(apis.selectType).then((res) => {
        if (res.data.code === 0) {
          this.selectTypeList = res.data.data;
        }
      });
    },
    /**
     * @function 编辑数据回显
     */
    initForm(data) {
      this.fileList = [];
      this.form.typeIds = data.typeId;
      this.form.videoAddress = data.videoAddress;
      this.form.videoName = data.venueVideoCourse.videoName;
      this.form.detail = data.venueVideoCourse.details;
      this.form.positioning = data.venueVideoCourse.positioning;
      this.form.price = data.venueVideoCourse.price;
      this.form.linePrice = data.venueVideoCourse.linePrice;
      this.form.videoImgAddress = data.venueVideoCourse.videoImgAddress;
      this.form.publishStatus = data.venueVideoCourse.publishStatus;
      if (data.videoAddress && data.videoAddress.length > 0) {
        this.fileList.push({
          url: data.videoAddress[0],
          name: this.form.videoName,
        });
      }
    },
    getDetail(id) {
      this.$http.get(`${apis.selectOneCourse}?courseId=${id}`).then((res) => {
        if (res.data.code === 0) {
          this.initForm(res.data.data);
        }
      });
    },
    searchData() {},
  },
  mounted() {
    this.getSelectTypeList();
    this.id = "";
    if (this.$route.query.Id) {
      this.id = this.$route.query.Id;
      this.getDetail(this.id);
      this.isEdit = true;
    } else {
      this.$nextTick(() => {
        this.$refs.form.resetFields();
      });
      this.isEdit = false;
    }
  },
  activated() {
    this.getSelectTypeList();
    this.searchData();
    this.fileList = [];
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.wrap-box {
  overflow: auto;
  height: 100%;
  padding: 0 20px;
  .form-content {
    height: 100%;
    margin-top: 20px;
    .video-input {
      position: relative;
      .video-text {
        position: absolute;
        right: 5px;
        top: 0px;
        z-index: 1000;
        color: #999999;
      }
    }
  }
  .wrap-info {
    line-height: 48px;
    color: #333333;
    border-bottom: 1px solid #d8d8d8;
  }
  .suggested-text {
    font-size: 14px;
    color: #999999;
  }
  .upload-btn {
    width: 78px;
    height: 28px;
    background: #ffffff;
    border-radius: 3px;
    border: 1px solid #439bff;
    color: #439bff;
    text-align: center;
    line-height: 28px;
  }
  .btn-box {
    height: 180px;
    text-align: center;
  }
  .w50 {
    width: 42px;
  }
  .price-box {
    display: flex;
    margin-top: 20px;
  }
  .price-input {
    margin-left: 20px;
    width: 220px;
  }
  .mg {
    margin-left: 10px;
  }
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
.convenience {
  .ql-editor {
    .ql-editor {
      height: 188px;
    }
  }
  .edit_container {
    .ql-snow {
      .ql-tooltip {
        // left: 10px !important;
      }
    }
  }
}
</style>
